import React from 'react'
import { inject, observer } from 'mobx-react';
import { withStyles } from "@material-ui/core/styles/index";
import Moment from "moment";
import classNames from 'classnames';
import { CircularProgress, Drawer, Grid, IconButton, Tooltip } from '@material-ui/core'
import {
    Cancel as CancelIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ViewDayOutlined as ViewDay,
    ViewWeekOutlined as ViewWeek,
    ViewModuleOutlined as ViewModuleIcon
} from "@material-ui/icons";
import SplitPane from "react-split-pane";

import Header from '../../template/Header/Header'
import PanelLeft from "../../components/PanelLeft/PanelLeft";
import DayPicker from "../../components/DayPicker";
import WeekPicker from "../../components/WeekPicker";
import Footer from "../../components/Footer/Footer";
import styles from './Atendimento.style';
import CheckboxAtendimento from "../../components/Checkbox/CheckboxAtendimento";
import FiltroSituacao from '../Atendimento/FiltroSituacao';
import AtendimentoModal from "./AtendimentoModal";
import Profile from '../../template/Header/Profile';
import WeekCalendar from '../../components/Agenda/WeekCalendar/WeekCalendar'
import AlertDialog from '../../components/Dialog/AlertDialog';
import FiltroHeader from '../../template/Header/FiltroHeader';
import { Radio } from '../../components/RadioButton/RadioButton';
import DayCalendar from '../../components/Agenda/DayCalendar/DayCalendar';
import Encaixe from "../../components/Agenda/Encaixe/Encaixe";
import InputSearchFilter from "../../components/Input/InputSearchFilter";
import ListaAgendamentoPaciente from './ListaAgendamentoPaciente'
import ListaHorariosDisponiveis from './ListaHorariosDisponiveis'
import Notification from '../../components/Notification';
import CalendarioMinimizadoSemana from './CalendarioMinimizadoSemana';
import CalendarioMinimizadoDia from './CalendarioMinimizadoDia';
import CalendarioMinimizadoThreeDays from './CalendarioMinimizadoThreeDays';
import ModalProcedimentosRealizados from "../SujeitoAtencao/Manutencao/ModalProcedimentosRealizados";
import MultiAgenda from '../../components/Agenda/MultiAgenda/MultiAgenda';
import { getProfissionalSaudeLogado } from '../../services/UsuarioService';
import AtendimentoButtonHeader from './AtendimentoButtonHeader';
import SelectCustomIcon from '../Configuracoes/ModelosDocumento/Anamnese/SelectCustomIcon';
import {
    AgendaDiaIcon,
    AgendaMultiagenda,
    AgendaSemana,
    AgendaTresDias,
    AgendaTresDiasBranco,
    ArrowAmpliarIcon,
    ArrowLeft,
    ArrowRight,
} from "../../assets/img/svg";
import ThreeDaysCalendar from '../../components/Agenda/ThreeDaysCalendar/ThreeDaysCalendar';
import ButtonLong from '../../components/Button/ButtonLong';
import ThreeDaysPicker from '../../components/ThreeDaysPicker';
import PageTitle from '../../components/PageTitle/PageTitle';
import ModalBloquearAgenda from './ModalBloquear/ModalBloquearAgenda';
import ModalConfirmarBloqueio from './ModalBloquear/ModalConfirmarBloqueio';
import ModalListaEspera from './ModalListaEspera';
import { Button } from '../../components/ui/Buttons';
import LockIcon from '../../components/Icon/LockIcon';
import UnlockIcon from '../../components/Icon/UnlockIcon';
import { contemAgendamento, modificarHorarioAtendimentoCascada } from '../../services/AgendamentoService';
import { getCheckedTimes, getMinandMaxTime } from './utils/timesutils';
import moment from 'moment';
import { createValidacaoHorariosVariables } from './utils/createValidacaoHorariosVariables';
import { generateObject } from './ModalBloquear/util/utils';
import { orcamentoDefault } from '../../stores/Orcamento.store';
import CancelarAgendamentoIcon from '../../components/Icon/CancelarAgendamentoIcon';
import ModalCancelamento from './ModalCancelamento/ModalCancelamento';
import ModalConfirmarCancelamento from './ModalCancelamento/modalConfirmarCancelamento';
import ModalMensagemWhatsapp from './ModalMensagemWhatsapp';
import FinalVigencia from '../../components/Agenda/DayCalendar/FinalVIgencia';
import { checkUserRole } from '../../utils/checkUserRole';
import ModalListaEsperaItem from './ModalListaEsperaItem';


export const typesCalendarView = [
    { icon: AgendaDiaIcon, value: 'dayView', selected: false, label: 'Dia', iconRadio: ViewDay },
    { icon: AgendaMultiagenda, value: 'weekView', selected: true, label: 'Semanal', iconRadio: ViewModuleIcon },
    { icon: AgendaTresDias, value: 'threeDaysView', selected: true, label: '3 dias', iconRadio: AgendaTresDiasBranco },
    { icon: AgendaSemana, value: 'multiAgendaView', selected: true, label: 'Multiagenda', iconRadio: ViewWeek },
];

@inject('atendimentoStore', 'sujeitoAtencaoStore', 'googleAnalyticsStore', 'profissionalSaudeStore', 'usuarioStore', 'filtroHeaderStore', 'unidadeStore', 'configuracaoImpressaoStore', 'odontogramaStore', 'orcamentoStore')
@observer
class Atendimento extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {
            openRemoveAgendamentoAlert: false,
            removeAgendamentoId: null,
            removeAgendamentoAlertTitle: "",
            loadingRemoveAgendamento: false,
            permissionBlockingAgenda: false,
            listaFilter: { last: false, content: [], pageable: { pageNumber: 0 } },
            searchPacient: '',
            notification: {
                open: false,
                message: 'Erro',
                type: 'error'
            },
            filterPacient: {
                profissionalSaudeId: null,
                dataInicio: null,
                dataFim: null,
            },
            filterHorarioDisponivel: {
                profissionalSaudeId: null,
                dataInicio: Moment(),
                dataFim: null,
                periodos: { label: 'Todos', value: { horaInicio: '00:00', horaFim: '23:59' } },
                especialidadeMedica: null,
                tipoConsulta: null,
            },
            openDrawer: true,
            showModalProcedimentosRealizados: true,
            filterApplied: false,
            nomeExibidoSearch: '',
            nomeProfissionalLogado: '',
            isRoleAgendamentoReadOutrosProfissionais: false,
            unidadeLogada: null,
            closeAgendamentoModal: false,
            tipoAgendaSelecionado: null,
            isTelaCheia: false,
            openModalBloquear: false,
            openModalConfirmacao: false,
            operation: '',
            horariosPendentes: [],
            isLoadingModificarHorario: false,
            openModalCancelamento:false,
            openModalConfirmarCancelamento:false,
            isLoading:false,
            openModalMensagemWhatsapp:false,
            loadListaEspera: null,
            isRefreshingAgenda: false,
        };
        this.agendaPulling = null;
        this.props.orcamentoStore.orcamento = orcamentoDefault;
    }
    async componentDidMount() {
        const { atendimentoStore, googleAnalyticsStore, unidadeStore } = this.props;
        const { isSalaSelected } = atendimentoStore;
        const permissionBlockingAgenda = await checkUserRole("ROLE_PERIODO_ATENDIMENTO_MODIFICADO_UPDATE")

        await unidadeStore.inicializaConfiguracaoUnidade();

        this.setState({ permissionBlockingAgenda })

        this.setState({isLoading:true})

        const filtroDatasPaciente = !!localStorage.getItem('filto_datas_agenda') && JSON.parse(localStorage.getItem('filto_datas_agenda'));
        filtroDatasPaciente && this.setState({
            filterPacient: {
                ...this.state.filterPacient,
                dataInicio: filtroDatasPaciente?.dataInicio,
                dataFim: filtroDatasPaciente?.dataFim
            }
        });
        atendimentoStore.controleSessaoData = {
            idPacote: null,
            idSujeitoAtencao: null,
            isProcedure: null,
        };
        atendimentoStore.recorrenciaControleSessao = null;
        atendimentoStore.typeCalendarViewSelected === "filterPacientView" && this.inicializaCalendarioDia();

        this.typeCalendarSelected()

        if (!unidadeStore.unidade?.id) {
            await unidadeStore.getUnidadeLogada();
        }
        atendimentoStore.utilizaTelefoneInternacional = unidadeStore.unidade.utilizaTelefoneInternacional;

        this.carregaFiltrosSearch();

        await this.refreshAgenda();

        const paginaVisualizada = {
            page: window.location.hash,
            title: 'Agenda'
        }
        googleAnalyticsStore.pageView(paginaVisualizada);

        const usuarioLogado = JSON.parse(localStorage[`_immortal|usuarioLogado`] || null);
        const profissionalSaudeLogado = await getProfissionalSaudeLogado();
        const tipoProfissional = this.getTipoProfissionalLogado(profissionalSaudeLogado?.tipo);
        this.setState({ nomeProfissionalLogado: profissionalSaudeLogado?.nome })
        atendimentoStore.setTipoProfissionalLogado(tipoProfissional);


        const isRoleAgendamentoReadOutrosProfissionais = usuarioLogado.authorities.some(a =>
            a.authority === 'ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS');

        this.setState({
            isRoleAgendamentoReadOutrosProfissionais: isRoleAgendamentoReadOutrosProfissionais
        })

        this.props.atendimentoStore.permiteVerAgendaOutrosProfissionais = isRoleAgendamentoReadOutrosProfissionais

        if (tipoProfissional !== 'tecnico') {
            await atendimentoStore.initObjectView(atendimentoStore.selectedDate);
            atendimentoStore.finishedInitialLoad = true
        }

        if (tipoProfissional === 'tecnico' || isSalaSelected) {
            atendimentoStore.initObjectViewSala(atendimentoStore.selectedDate);
        }

        if (960 >= innerWidth) {
            this.setState({ openDrawer: false })
        }

        this.setState({isLoading:false,})
    }

    getTipoProfissionalLogado = (tipoProfissional) => {
        switch (tipoProfissional) {
            case 1:
                return 'medico'
            case 2:
                return 'secretario'
            case 3:
                return 'administrador'
            case 4:
                return 'tecnico'
            default:
                return null;
        }
    };

    inicializaCalendarioDia = () => {
        const { atendimentoStore } = this.props;
        atendimentoStore.typeCalendarViewSelected = "";
        this.onChangeTypeCalendarView("dayView");
    };

    openModal = async(event, isModalSala) => {
        const { atendimentoStore } = this.props;
        const { tipoProfissionalLogado } = this.props.atendimentoStore;
        

        if (tipoProfissionalLogado === 'tecnico') {
            if (!event?.id) return
            this.props.atendimentoStore.changeOpen({
                id: event?.id || null,
                horaInicio: event?.start,
                horaFim: event?.end,
                status: event?.status,
                agendaSelecionada: event?.agendaSelecionada,
                tipoModalAtendimento: 'tecnico'
            });
        } else {
            if (!event?.start) {
                const hora = Moment().format('LTS');
                const dataSelecionada = atendimentoStore.selectedDate.format("YYYY-MM-DD");
                const horaInicio = Moment(`"${dataSelecionada} ${hora}"`, 'YYYY-MM-DD HH:mm:ss');
                const horaFim = Moment(horaInicio).add(
                    atendimentoStore.configVigente?.duracao,
                    "minutes"
                );

                return this.props.atendimentoStore.changeOpen({
                    id: event?.id || null,
                    horaInicio: horaInicio,
                    horaFim: horaFim,
                    status: "AGENDADO",
                    ...(isModalSala && {
                        isModalSala,
                        profissionalSolicitanteConfig: event?.profissionalSolicitanteConfig,
                        procedimentosConfig: event?.procedimentosConfig,
                    }),
                    agendaSelecionada: event?.agendaSelecionada
                });
            };
            this.props.atendimentoStore.changeOpen({
                id: event?.id || null,
                horaInicio: event?.start,
                horaFim: event?.end,
                status: event?.status,
                ...(isModalSala && {
                    isModalSala,
                    profissionalSolicitanteConfig: event?.profissionalSolicitanteConfig,
                    procedimentosConfig: event?.procedimentosConfig,
                }),
                agendaSelecionada: event?.agendaSelecionada,
                chavePublica: event?.chavePublica
            });
        }

        ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Modal agendamento', 'Abrir modal agendamento');
        this.props.googleAnalyticsStore.tempoInicioAgendamento = Moment().format('x');
    };

    openModalHorariosDisponiveis = (event) => {
        const { atendimentoStore } = this.props;
        const { id, horaInicio, horaFim, data, profissionalSaudeId } = event || {};
        atendimentoStore.changeOpenHorarioDisponivel(id, horaInicio, horaFim, data, profissionalSaudeId);
    }

    resetarSeletoresAgenda = () => {
        const { atendimentoStore } = this.props;
        atendimentoStore.isMultiAgendaSelected = false;
        atendimentoStore.isEncaixeSelected = false;
    }

    carregaFiltrosSearch = async () => {
        const { usuarioStore, profissionalSaudeStore } = this.props

        const unidadeLogada = await usuarioStore.getUnidadeAtual();
        const listaProfissinalSaude = await profissionalSaudeStore.findByUnidadeComAgenda(unidadeLogada?.id);
        this.props.atendimentoStore.listaProfissionalSaudeComAgenda = listaProfissinalSaude;

        this.setState({
            listaProfissinalSaude,
            unidadeLogada
        })
    }

    handleChangeFilterPacient = (value, field) => {
        this.setState({
            filterPacient: {
                ...this.state.filterPacient,
                [field]: value
            }
        })
    };

    handleChangeSearchFilter = (value, field, filter = null) => {
        const dadosFilter = filter ? this.state.filterHorarioDisponivel : this.state.filterPacient;

        this.setState({
            [filter || "filterPacient"]: {
                ...dadosFilter,
                [field]: value
            }
        }, () => {
            if (!filter) {
                const filtroDatas = {
                    dataInicio: this.state.filterPacient.dataInicio && Moment(this.state.filterPacient.dataInicio).format("YYYY-MM-DD"),
                    dataFim: this.state.filterPacient.dataFim && Moment(this.state.filterPacient.dataFim).format("YYYY-MM-DD"),
                }

                localStorage.setItem('filto_datas_agenda', JSON.stringify(filtroDatas));
            };
        });
    };

    redefinirFiltros = () => {
        const redefinirFiltros = {
            ativo: { name: 'Ativos', label: 'Ativos', value: true },
            municipioId: null,
            profissionalSaudeId: null,
            horariosDisponiveis: false,
            dataInicio: null,
            dataFim: null
        }
        this.setState({
            filterPacient: redefinirFiltros,
            filterHorarioDisponivel: {
                dataInicio: Moment().format("YYYY-MM-DD"),
                dataFim: null,
                profissionalSaudeId: null,
                periodos: { label: 'Todos', value: { horaInicio: '00:00', horaFim: '23:59' } },
            },
            filterApplied: false
        });

        const filtroDatas = {
            dataInicio: null,
            dataFim: null,
        };
        localStorage.setItem('filto_datas_agenda', JSON.stringify(filtroDatas));
    }

    closeAlertMessage = () => {
        this.setState(prevState => ({
            ...prevState,
            notification: {
                ...prevState.notification,
                open: false,
            }
        }));
    };
    handleDrawerOpen = () => {
        this.setState({ openDrawer: true });
    };

    handleDrawerClose = () => {
        this.setState({ openDrawer: false });
    };

    handleExcluirAgendamento = (agendamento) => {
        const { atendimentoStore } = this.props;
        if (atendimentoStore.tipoProfissionalLogado === 'tecnico') return;

        atendimentoStore.openExcluirAgendamentoAlert(agendamento);
        ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Agendamento', 'Click direito para excluir agendamento');
    };
    handleExcluirAgendamentoOk = async () => {
        try {
            const { atendimentoStore } = this.props;
            const isRecorrencia = Array.isArray(atendimentoStore.excluirAgendamentoAlertData?.agendamento);
            const id = !isRecorrencia && atendimentoStore.excluirAgendamentoAlertData.agendamento.id;
            isRecorrencia ?
                await atendimentoStore.excluirAgendamentoRecorrencia()
                : await atendimentoStore.excluirAgendamento({ id });
            this.handleExcluirAgendamentoCancel();
            this.setState({ closeAgendamentoModal: true })
        } catch (error) {
            console.error(error);
        }
    };

    handleExcluirAgendamentoCancel = () => {
        const { atendimentoStore } = this.props;
        atendimentoStore.closeExcluirAgendamentoAlert();
    };

    getWeek = (date) => {
        this.props.atendimentoStore.selectedDate = date;
        this.props.atendimentoStore.initObjectView(date);
    };

    semanaAnterior = () => {
        const { atendimentoStore } = this.props;
        const start = Moment(atendimentoStore.selectedDate).day(0);
        atendimentoStore.selectedDate = start.subtract(1, 'week');
        atendimentoStore.initObjectView(atendimentoStore.selectedDate);
    };

    semanaPosterior = () => {
        const { atendimentoStore } = this.props;
        const start = Moment(atendimentoStore.selectedDate).day(0);
        atendimentoStore.selectedDate = start.add(1, 'week');
        atendimentoStore.initObjectView(atendimentoStore.selectedDate);
    }

    getDay = (date) => {
        if (this.props.atendimentoStore.typeCalendarViewSelected === 'filterPacientView') {
            this.props.atendimentoStore.typeCalendarViewSelected = 'dayView';
        }
        this.props.atendimentoStore.selectedDate = date;
        this.props.atendimentoStore.initObjectView(date);
    };

    dataPosterior = () => {
        const { atendimentoStore } = this.props;
        const dataAtual = Moment(atendimentoStore.selectedDate);

        atendimentoStore.selectedDate = dataAtual.add(1, 'day');
        atendimentoStore.initObjectView(atendimentoStore.selectedDate);
    };

    dataAnterior = () => {
        const { atendimentoStore } = this.props;
        const dataAtual = Moment(atendimentoStore.selectedDate);

        atendimentoStore.selectedDate = dataAtual.subtract(1, 'day');
        this.renderCalendar(atendimentoStore.typeCalendarViewSelected);
        atendimentoStore.initObjectView(atendimentoStore.selectedDate)
    };

    changeCheckbox = (e) => {
        const { isSalaSelected, tipoProfissionalLogado, isMultiAgendaSelected } = this.props.atendimentoStore;
        for (let i of this.props.atendimentoStore.situacoes) {
            if (i.value === e.target.value) {
                i.disabled = !e.target.checked;
                break;
            }
        }

        if (isMultiAgendaSelected) {
            return this.props.atendimentoStore.filterStatus()
        };

        this.props.atendimentoStore.filterEvents();
        if (isSalaSelected || tipoProfissionalLogado === 'tecnico') {
            this.props.atendimentoStore.filterEventsSala();
        }
        ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Filtros da agenda', 'Status do agendamento');
    };

    typeCalendarSelected = () => {
        const { atendimentoStore } = this.props;
        const typeCalendar = typesCalendarView.find(item => item.value === atendimentoStore.typeCalendarViewSelected);

        this.setState({ tipoAgendaSelecionado: typeCalendar || '' });
    };

    onChangeTypeCalendarView = (typeCalendarView) => {
        const { atendimentoStore } = this.props;
        const { tipoProfissionalLogado, isSalaSelected } = atendimentoStore;
        atendimentoStore.typeCalendarViewSelected = typeCalendarView;
        this.typeCalendarSelected()

        if (typeCalendarView === 'dayView') {
            atendimentoStore.isEncaixeSelected = false;
            atendimentoStore.isMultiAgendaSelected = false;
            this.clearPesquisaPacientes();
            ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Alterar agenda', 'Alterar para dia')
        }
        if (typeCalendarView === 'weekView') {
            atendimentoStore.isEncaixeSelected = false;
            atendimentoStore.isMultiAgendaSelected = false;
            ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Alterar agenda', 'Alterar para semana')
        }

        if (typeCalendarView === 'multiAgendaView') {
            atendimentoStore.isSalaSelected = false;
            atendimentoStore.isEncaixeSelected = false;
            atendimentoStore.isMultiAgendaSelected = true;
        }

        if (tipoProfissionalLogado !== 'tecnico') {
            this.props.atendimentoStore.initObjectView(this.props.atendimentoStore.selectedDate);
        }

        if (tipoProfissionalLogado === 'tecnico' || isSalaSelected) {
            this.props.atendimentoStore.initObjectViewSala(this.props.atendimentoStore.selectedDate);
        }
    };

    refreshAgenda = async () => {
        const { isRefreshingAgenda } = this.state;
        if (isRefreshingAgenda) return;

        this.setState({ isRefreshingAgenda: true }, async () => {
            await this.loadAgendamentos();
        });
    };

    loadAgendamentos = async () => {
        const { atendimentoStore } = this.props;
        const { tipoProfissionalLogado, isSalaSelected, isMultiAgendaSelected, selectedDate } = atendimentoStore;

        if (tipoProfissionalLogado !== 'tecnico' && !isMultiAgendaSelected) {
            const diaSelecionadoAux = selectedDate;
            await atendimentoStore.atualizaAgendamentos(
                selectedDate,
                Moment(diaSelecionadoAux).add(3, 'days')
            );
        } 

        if (isSalaSelected || tipoProfissionalLogado === 'tecnico') {
            await atendimentoStore.atualizaAgendamentosSala(selectedDate);
        }
        
        this.setState({ isRefreshingAgenda: false }, () => {
            this.resetagendaPulling();
        });
    }
    
    resetagendaPulling = () => {
        this.agendaPulling = setTimeout(
            async () => {
                await this.refreshAgenda();
            },
            15000
        );
    };

    onClickCurrentDayButton = () => {
        const { atendimentoStore } = this.props;
        const { tipoProfissionalLogado, isSalaSelected } = this.props.atendimentoStore;
        const diaAtual = new Date();
        atendimentoStore.selectedDate = Moment(diaAtual);
        if (tipoProfissionalLogado !== 'tecnico') {
            this.props.atendimentoStore.initObjectView(this.props.atendimentoStore.selectedDate);
        }

        if (tipoProfissionalLogado === 'tecnico' || isSalaSelected) {
            this.props.atendimentoStore.initObjectViewSala(this.props.atendimentoStore.selectedDate);
        }
    }

    componentWillUnmount() {
        const { atendimentoStore } = this.props;

        atendimentoStore.finishedInitialLoad = false;
        clearInterval(this.agendaPulling);
        this.agendaPulling = null;
        this.resetarSeletoresAgenda();
    }

    renderPicker(typeCalendarViewSelected) {
        const { atendimentoStore } = this.props;
        if (typeCalendarViewSelected === 'weekView') {
            return this.state.openDrawer ?
                <WeekPicker allowKeyboardControl={!atendimentoStore.open}
                    onChange={this.getWeek}
                    date={atendimentoStore.selectedDate}
                    openDrawer={this.state.openDrawer}
                />
                :
                <CalendarioMinimizadoSemana semanaPosterior={this.semanaPosterior}
                    semanaAnterior={this.semanaAnterior}
                    onClickCurrentDayButton={this.onClickCurrentDayButton}

                />
        }
        else if (typeCalendarViewSelected === 'threeDaysView') {
            return this.state.openDrawer ?
                <ThreeDaysPicker allowKeyboardControl={!atendimentoStore.open}
                    onChange={this.getWeek}
                    date={atendimentoStore.selectedDate}
                    openDrawer={this.state.openDrawer}
                />
                :
                <CalendarioMinimizadoThreeDays dataPosterior={this.dataPosterior}
                    dataAnterior={this.dataAnterior}
                    onClickCurrentDayButton={this.onClickCurrentDayButton}
                />

        }

        return this.state.openDrawer ?
            <DayPicker allowKeyboardControl={!atendimentoStore.open}
                onChange={this.getDay}
                date={atendimentoStore.selectedDate}
                openDrawer={this.state.openDrawer}
                onChangeTypeCalendarView={this.onChangeTypeCalendarView}
            />
            :
            <CalendarioMinimizadoDia dataPosterior={this.dataPosterior}
                dataAnterior={this.dataAnterior}
                onClickCurrentDayButton={this.onClickCurrentDayButton}
            />
    }

    renderCalendar(typeCalendarViewSelected, salaCalendar) {
        let typeCalendarSelected = typeCalendarViewSelected;
        const { atendimentoStore, classes, filtroHeaderStore, sujeitoAtencaoStore, history, unidadeStore, configuracaoImpressaoStore } = this.props;
        const { configVigente, configVigenteSala, loadingCalendar, finishedInitialLoad } = atendimentoStore;
        const { salaId } = atendimentoStore.getSelectValuesFromFiltroStore();

        if (loadingCalendar || !finishedInitialLoad) return (
            <div className={classes.contentLoadingContainer}>
                <CircularProgress />
            </div>
        )

        if (salaCalendar && typeCalendarViewSelected === 'weekView') {
            typeCalendarSelected = 'weekViewSala'
        }

        if (salaCalendar && typeCalendarViewSelected !== 'weekView') {
            typeCalendarSelected = 'salaDay'
        }
        
        if (salaCalendar && atendimentoStore.calendarErrorMessageSala) {
            return (
                <div className={classes.contentLoadingContainer}>
                    <h3 className={classes.notFoundTitle}>{atendimentoStore.calendarErrorMessageSala}</h3>
                </div>
            )
        }

        
        if (salaCalendar && !configVigenteSala && (salaId && salaId !== '0')) {
            return (<FinalVigencia salaCalendar={salaCalendar} />)
        }

        if (!configVigente && !salaCalendar && finishedInitialLoad) {
            return <FinalVigencia />;
        }

        if (
            atendimentoStore.tipoProfissionalLogado !== 'tecnico' &&
            (atendimentoStore.loadingCalendar || filtroHeaderStore.filtroHeaderErrorMessage)) return
        switch (typeCalendarSelected) {
            case 'weekView':
                return <WeekCalendar
                    agendamentos={atendimentoStore.eventosFiltrados}
                    selectedDate={atendimentoStore.selectedDate}
                    configVigente={atendimentoStore.configVigente}
                    onSelectAgendamento={this.openModal}
                    onSelectNovoAgendamento={this.openModal}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                    sujeitoAtencaoStore={sujeitoAtencaoStore}
                    history={history}
                />
            case 'weekViewSala':
                return <WeekCalendar
                    weekViewSala
                    agendamentos={atendimentoStore.eventosFiltradosSala}
                    selectedDate={atendimentoStore.selectedDate}
                    configVigente={atendimentoStore.configVigenteSala}
                    onSelectAgendamento={(e) => this.openModal(e, 'isModalSala')}
                    onSelectNovoAgendamento={(e) => this.openModal(e, 'isModalSala')}
                    tipoProfissionalLogado={atendimentoStore.tipoProfissionalLogado}
                    sujeitoAtencaoStore={sujeitoAtencaoStore}
                    history={history}
                />
            case 'filterPacientView':
                return <ListaAgendamentoPaciente
                    lista={atendimentoStore.listaFilter.content}
                    searchPacient={this.state.nomeExibidoSearch}
                    refreshList={this.handleClickSearch}
                    intervaloDuracao={atendimentoStore.objView.duracao}
                    atendimentoStore={atendimentoStore}
                    loadMore={this.findAllAgendamentoBySearchPageable}
                    onSelectAgendamento={this.openModal}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                />;
            case 'filterHorariosDisponiveisView':
                return <ListaHorariosDisponiveis
                    horariosDisponiveis={atendimentoStore.horariosDisponiveis.content}
                    searchPacient={this.state.nomeExibidoSearch}
                    refreshList={this.handleClickSearch}
                    intervaloDuracao={atendimentoStore.objView.duracao}
                    atendimentoStore={atendimentoStore}
                    loadMore={this.findAllHorariosDisponiveis}
                    onSelectAgendamento={this.openModalHorariosDisponiveis}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                />;
            case 'encaixesView':
                return <Encaixe
                    agendamentos={atendimentoStore.eventosFiltradosEncaixes}
                    selectedDate={atendimentoStore.selectedDate}
                    configVigente={atendimentoStore.configVigente}
                    onSelectAgendamento={this.openModal}
                    onSelectNovoAgendamento={this.openModal}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                />

            case 'salaDay':
                return <DayCalendar
                    salaDay
                    agendamentos={atendimentoStore.eventosFiltradosSala}
                    todosAgendamentos={atendimentoStore.eventosSala || []}
                    selectedDate={atendimentoStore.selectedDate}
                    configVigente={atendimentoStore.configVigenteSala}
                    onSelectAgendamento={(e) => this.openModal(e, 'isModalSala')}
                    onSelectNovoAgendamento={(e) => this.openModal(e, 'isModalSala')}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                    configuracaoImpressaoStore={configuracaoImpressaoStore}
                    tipoProfissionalLogado={atendimentoStore.tipoProfissionalLogado}
                    history={history}
                />;
            case 'threeDaysView':
                return <ThreeDaysCalendar
                    agendamentos={atendimentoStore.eventosFiltrados}
                    todosAgendamentos={atendimentoStore.eventos || []}
                    selectedDate={atendimentoStore.selectedDate}
                    configVigente={atendimentoStore.configVigente}
                    onSelectAgendamento={this.openModal}
                    onSelectNovoAgendamento={this.openModal}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                    tipoProfissionalLogado={atendimentoStore.tipoProfissionalLogado}
                />;
            case 'multiAgendaView':
                return <MultiAgenda
                    onSelectAgendamento={this.openModal}
                />;
            default:
                return <DayCalendar
                    agendamentos={atendimentoStore.eventosFiltrados}
                    todosAgendamentos={atendimentoStore.eventos || []}
                    selectedDate={atendimentoStore.selectedDate}
                    configVigente={atendimentoStore.configVigente}
                    onSelectAgendamento={this.openModal}
                    onSelectNovoAgendamento={this.openModal}
                    onRemoveAgendamento={this.handleExcluirAgendamento}
                    utilizaTelefoneInternacional={unidadeStore.unidade.utilizaTelefoneInternacional}
                    configuracaoImpressaoStore={configuracaoImpressaoStore}
                    nomeProfissionalSelecionado={filtroHeaderStore.profissionalSaude.nome}
                    history={history}
                />;

        }
    }

    onChangePacient = (e) => {
        const { value, name } = e.target;
        this.setState({ [name]: value });
    };

    onChangeSearch = (e) => {
        this.setState({ searchPacient: e.target.value })
    };

    onKeypressSearchPacient = (e) => {
        if (e.key === 'Enter' && this.state.searchPacient.length > 3) {
            this.setState({ nomeExibidoSearch: this.state.searchPacient })
            this.handleClickSearch();
        }
    };

    handleClickClear = () => {
        this.clearPesquisaPacientes();
        this.onChangeTypeCalendarView('dayView');
        ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Pesquisa de pacientes', 'Limpar busca de pacientes')
    };

    clearPesquisaPacientes = () => {
        this.setState({ searchPacient: '', filterApplied: false });
    }

    handleClickSearch = () => {
        if (this.state.searchPacient.length <= 3 || this.props.atendimentoStore.loadingFindAllAgendamentoEEncaixe) return;
        this.setState({
            filterApplied: true,
            nomeExibidoSearch: this.state.searchPacient
        });
        this.props.atendimentoStore.isEncaixeSelected = false;
        this.props.atendimentoStore.isSalaSelected = false;
        this.props.atendimentoStore.isMultiAgendaSelected = false;
        this.props.atendimentoStore.listaFilter = { last: false, content: [], pageable: { pageNumber: 0 } }
        if (this.state.searchPacient) {
            this.onChangeTypeCalendarView('filterPacientView');
            this.findAllAgendamentoBySearchPageable(0);
        }
        ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Pesquisa de pacientes', 'Buscar pacientes')
    };

    handleClickSearchHorariosDisponiveis = () => {
        const { dataInicio, dataFim } = this.state.filterPacient;
        if (dataInicio && dataFim && dataInicio > dataFim) {
            return (this.setState({
                notification: {
                    open: true,
                    message: 'Data inicial não deve ser maior que a data final.',
                    type: 'error'
                }
            }))
        }

        this.setState({
            filterApplied: true,
        });
        this.props.atendimentoStore.loadingHorariosDisponiveis = true;
        this.props.atendimentoStore.isEncaixeSelected = false;
        this.props.atendimentoStore.isSalaSelected = false;
        this.props.atendimentoStore.isMultiAgendaSelected = false;
        this.props.atendimentoStore.horariosDisponiveis = { last: false, content: [], pageable: { pageNumber: 0 } }

        this.onChangeTypeCalendarView('filterHorariosDisponiveisView');
        this.findAllHorariosDisponiveis();
    }

    findAllHorariosDisponiveis = async () => {
        const { atendimentoStore } = this.props;

        atendimentoStore.horariosDisponiveis.filterVariables = this.state.filterPacient;
        const variables = {
            ...this.state.filterHorarioDisponivel,
        }
        await atendimentoStore.findAllHorariosLivresUnidade(variables);

        atendimentoStore.horariosDisponiveis.pageable.pageNumber++;
        atendimentoStore.loadingHorariosDisponiveis = false;
    };

    findAllAgendamentoBySearchPageable = (pageNumber = 0) => {
        this.props.atendimentoStore.listaFilter.pageNumber = pageNumber;
        this.props.atendimentoStore.listaFilter.search = this.state.searchPacient
        this.props.atendimentoStore.listaFilter.searchDTO = this.state.filterPacient;
        this.props.atendimentoStore.atualizaAgendamentosFilter();
    };

    handleClickEncaixes = () => {
        const { atendimentoStore, googleAnalyticsStore } = this.props;
        atendimentoStore.isSalaSelected = false;
        atendimentoStore.isMultiAgendaSelected = false;

        if (atendimentoStore.isEncaixeSelected) {
            this.onChangeTypeCalendarView('dayView');
            atendimentoStore.isEncaixeSelected = false;
        } else {
            this.onChangeTypeCalendarView('encaixesView');
            atendimentoStore.isEncaixeSelected = true;
            ga(googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Alterar agenda', 'Alterar para encaixes')
        }
    };

    handleClickMultiAgenda = () => {
        const { atendimentoStore } = this.props;

        atendimentoStore.isSalaSelected = false;
        atendimentoStore.isEncaixeSelected = false;

        if (atendimentoStore.isMultiAgendaSelected) {
            this.onChangeTypeCalendarView('dayView');
            atendimentoStore.isMultiAgendaSelected = false;
        } else {
            this.onChangeTypeCalendarView('multiAgendaView');
            atendimentoStore.isMultiAgendaSelected = true;
        }
    }

    handleClickSalas = () => {
        const { atendimentoStore } = this.props;
        atendimentoStore.isMultiAgendaSelected = false;
        atendimentoStore.isEncaixeSelected = false;
        this.onChangeTypeCalendarView('dayView');
        if (atendimentoStore.isSalaSelected) {
            atendimentoStore.isSalaSelected = false;
            atendimentoStore.splitPosition = null;
        } else {
            atendimentoStore.isSalaSelected = true;
            ga(this.props.googleAnalyticsStore.profissionalSaude + '.send', 'event', 'Agenda', 'Alterar agenda', 'Alterar para encaixes')
        }
    };

    showFiltroHeader = () => {
        const { typeCalendarViewSelected } = this.props.atendimentoStore;

        return (
            typeCalendarViewSelected === 'filterPacientView' ||
            typeCalendarViewSelected === 'filterHorariosDisponiveisView' ||
            typeCalendarViewSelected === 'multiAgendaView'
        );
    }

    handleTelaCheia = () => {
        this.setState({ isTelaCheia: true });
    };

    handleDiaAnterior = () => {
        const { atendimentoStore } = this.props;

        const diaAnterior = Moment(atendimentoStore.selectedDate).subtract(1, 'days');
        const semanaAnterior = Moment(atendimentoStore.selectedDate).subtract(7, 'days');

        atendimentoStore.selectedDate = atendimentoStore.typeCalendarViewSelected === 'weekView' ? semanaAnterior : diaAnterior;
        this.refreshAgenda();
    };

    handleDiaPosterior = () => {
        const { atendimentoStore } = this.props;

        const diaAnterior = Moment(atendimentoStore.selectedDate).add(1, 'days');
        const semanaAnterior = Moment(atendimentoStore.selectedDate).add(7, 'days');

        atendimentoStore.selectedDate = atendimentoStore.typeCalendarViewSelected === 'weekView' ? semanaAnterior : diaAnterior;
        this.refreshAgenda();
    };

    handleSemTelaCheia = () => {
        this.setState({ isTelaCheia: false });
    };

    handleBloquearAgenda = (operation) => {
        const { atendimentoStore } = this.props;

        atendimentoStore.dataSelecionadaBloqueioAgenda = {
            dataInicial: null,
            dataFinal: null,
        };
        this.setState({ openModalBloquear: true, operation: operation });
    };
    handleCancelarEmMassa = ()=>{
        const { atendimentoStore } = this.props;
        this.setState({ openModalCancelamento: true,  });
        
    }

    renderHeaderCalendarioProfissional = () => {
        const { classes } = this.props;
        const { isTelaCheia } = this.state;
        if (this.showFiltroHeader()) return;

        return (
            <div className={classNames(classes.headerSelecaoAgendasProfissional, { [classes.headerSelecaoAgendasProfissionalTelaCheia]: isTelaCheia })}>
                <FiltroHeader screen={'AGENDAMENTO'} />
                <div className={classNames(classes.contentButtonsDiaAnteriorPosterior, { [classes.headerSelecaoAgendasProfissionalTelaCheia]: isTelaCheia })}>
                    {this.state.permissionBlockingAgenda && <div className={classes.lockButtonsContainer}>
                        <Tooltip title={"Liberar agenda"}>
                            <Button
                                shape="circle"
                                bgColor="#707C97"
                                onClick={() => this.handleBloquearAgenda('LIBERAR')}
                            >
                                <UnlockIcon size={24} color="#FFF" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Bloquear Agenda">
                            <Button
                                bgColor="#707C97"
                                shape="circle"
                                onClick={() => this.handleBloquearAgenda('BLOQUEAR')}
                            >
                                <LockIcon size={24} color="#FFF" />
                            </Button>
                        </Tooltip>
                    </div>
                    }
                    {
                        isTelaCheia ?
                            <div className={classes.contentButtonTelaCheia}>
                                <ButtonLong
                                    colorCustom="red"
                                    onClick={this.handleSemTelaCheia}
                                    noShadow
                                >
                                    Sair da tela cheia

                                </ButtonLong>
                            </div>
                            :
                            <div className={classes.contentButtonSemTelaCheia}>
                                <Tooltip title={"Tela cheia"}>
                                    <IconButton
                                        onClick={this.handleTelaCheia}
                                        className={classes.buttonTelaCheia}>
                                        <img
                                            src={ArrowAmpliarIcon}
                                            alt="ampliar agenda"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                    }
                    <div
                        className={classes.buttonDiaAnteriorPosterior}
                        onClick={this.handleDiaAnterior}
                    >
                        <img
                            src={ArrowLeft}
                            alt="dia anterior"
                            className={classes.iconButtonDiaAnteriorPosterior}
                        />
                    </div>
                    <div
                        className={classes.buttonDiaAnteriorPosterior}
                        onClick={this.handleDiaPosterior}
                    >
                        <img
                            src={ArrowRight}
                            alt="dia posterior"
                            className={classes.iconButtonDiaAnteriorPosterior}
                        />
                    </div>
                </div>
            </div>
        )
    };

    handleCloseModalConfirmacao = async () => {
        const { atendimentoStore } = this.props;
        this.setState({ openModalConfirmacao: false })
        atendimentoStore.initObjectView(atendimentoStore.selectedDate);
    }

    handleConfirmModificarAgenda = async () => {
        const { atendimentoStore, filtroHeaderStore } = this.props;
        const { profissionalSaude } = filtroHeaderStore;
        const { dataSelecionadaBloqueioAgenda, horariosOptions } = atendimentoStore;
        const { operation } = this.state;

        this.setState({ isLoadingModificarHorario: true });
        if (operation === 'BLOQUEAR') {
            try {
                const times = getCheckedTimes(horariosOptions);
                const { minTime, maxTime } = getMinandMaxTime(times);
                const periodos = createValidacaoHorariosVariables({
                    horaInicio: minTime,
                    horaFim: maxTime,
                    data: dataSelecionadaBloqueioAgenda,
                    profissionalSaudeId: profissionalSaude?.id
                })
                const horariosOcupados = await contemAgendamento(periodos);
                if (horariosOcupados?.length > 0) {
                    return this.setState({
                        openModalConfirmacao: true,
                        horariosPendentes: horariosOcupados,
                        openModalBloquear: false,
                        isLoadingModificarHorario: false
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.setState({ isLoadingModificarHorario: false });
            }

        }
        await this.onFinishModificarAgenda();
    }

    
    onFinishModificarAgenda = async () => {
        const { atendimentoStore } = this.props;
        const { operation } = this.state;
        const { dataSelecionadaBloqueioAgenda, horariosOptions, configVigente, profissionaisAgendaBloqueadaOptions } = atendimentoStore;
        const { duracao } = configVigente;
        const { dataInicial, dataFinal } = dataSelecionadaBloqueioAgenda;

        const profissionaisBloqueioAgendafiltered =
            profissionaisAgendaBloqueadaOptions.filter(
                (profissional) => profissional.checked && profissional.value !== "Todos os profissionais")

        const profissionaisBloqueioAgendaId = profissionaisBloqueioAgendafiltered.map((profissional) => profissional.value)

        try {
            const variables = {
                profissionaisSaude: profissionaisBloqueioAgendaId,
                periodos: generateObject({
                    horariosOptions,
                    dataInicial: moment(dataInicial).format("YYYY-MM-DD"),
                    dataFinal: moment(dataFinal).format("YYYY-MM-DD"),
                    duracao,
                    operation
                }),
            };
            this.setState({ isLoadingModificarHorario: true });
            await modificarHorarioAtendimentoCascada(variables);
        } catch (error) {
            console.error(error);
            this.setState({
                notification: {
                    message: 'Falha ao modificar o horário de atendimento',
                    type: 'error',
                    open: true
                }
            });
        } finally {
            this.setState({ isLoadingModificarHorario: false, openModalBloquear: false, openModalConfirmacao: false });
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }
    }

    handleCancelarAgendamentos = async () => {
        const { atendimentoStore, filtroHeaderStore } = this.props;
        const { profissionalSaude } = filtroHeaderStore;
        const { dataSelecionadaCancelaAgenda, horariosOptions } = atendimentoStore;
        
        
            try {
                const times = getCheckedTimes(horariosOptions);
                const { minTime, maxTime } = getMinandMaxTime(times);
                const periodos = createValidacaoHorariosVariables({
                    horaInicio: minTime,
                    horaFim: maxTime,
                    data: dataSelecionadaCancelaAgenda,
                    profissionalSaudeId: profissionalSaude?.id
                })
                const horariosOcupados = await contemAgendamento(periodos);
                if (horariosOcupados?.length > 0) {
                    return this.setState({
                        openModalConfirmarCancelamento: true,
                        horariosPendentes: horariosOcupados,
                        openModalCancelamento: false
                    });
                    
                }

            } catch (error) {
                console.error(error);
            }
        
            await this.onFinishCancelarAgendamentos()
    }

    
    onFinishCancelarAgendamentos = async () => {
        const { atendimentoStore, filtroHeaderStore } = this.props;
        const { operation } = this.state;
        const { profissionalSaude } = filtroHeaderStore;
        const { dataSelecionadaBloqueioAgenda, horariosOptions, configVigente } = atendimentoStore;
        const { duracao } = configVigente;
        const { dataInicial, dataFinal } = dataSelecionadaBloqueioAgenda;
       

        try {
            const variables = {
                profissionalSaudeId: profissionalSaude?.id,
                periodos: generateObject({
                    horariosOptions,
                    dataInicial: moment(dataInicial).format("YYYY-MM-DD"),
                    dataFinal: moment(dataFinal).format("YYYY-MM-DD"),
                    duracao,
                    operation
                }),
            };
            this.setState({ isLoadingModificarHorario: true });
            await modificarHorarioAtendimento(variables);
        } catch (error) {
            console.error(error);
            this.setState({
                notification: {
                    message: 'Falha ao cancelar horários de atendimento',
                    type: 'error',
                    open: true
                }
            });
        } finally {
            this.setState({ isLoadingModificarHorario: false, openModalConfirmarCancelamento: false, openModalCancelamento: false });
            atendimentoStore.initObjectView(atendimentoStore.selectedDate);
        }
    }


    handleModalConfirmarCancelamento = () => {
        this.setState({openModalConfirmarCancelamento:true,})
    }

    handleCloseModalConfirmarCancelamento = () => {
        this.setState({openModalConfirmarCancelamento:false,})
    }

    loadListaEspera = (carrega) => {
        this.setState({ loadListaEspera: carrega })
    }

    render() {
        const { classes, atendimentoStore, filtroHeaderStore } = this.props;
        const { openDrawer, openModalConfirmacao, horariosPendentes, operation, isLoadingModificarHorario, openModalBloquear } = this.state
        return (
            <div className={openDrawer ? classes.gridContainer : classes.gridContainerBarraMinimizado}>
                <PageTitle title="Agenda" />
                <Drawer
                    variant="permanent"
                    className={classNames(classes.drawerMinimizar, {
                        [classes.drawerOpen]: this.state.openDrawer,
                        [classes.drawerClose]: !this.state.openDrawer,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: this.state.openDrawer,
                            [classes.drawerClose]: !this.state.openDrawer,
                        }),
                    }}
                    open={this.state.openDrawer}
                >
                    <PanelLeft className={classes.panelLeft}>
                        <Grid item>
                            {openDrawer && (
                                <Header>
                                    <Grid item container alignItems={"center"} justify={"center"} xs={12}>
                                        <h3 className={classes.titleHeader}>Horário de Atendimento</h3>
                                    </Grid>
                                </Header>
                            )
                            }
                        </Grid>
                        <Grid item className={openDrawer ? classes.radioButtons : classes.radioButtonsMinimizado}>
                            {
                                openDrawer ?
                                    <SelectCustomIcon
                                        placeholder="Selecione"
                                        label=""
                                        isSelect
                                        onChange={(event) => this.onChangeTypeCalendarView(event.value)}
                                        value={this.state.tipoAgendaSelecionado}
                                        options={typesCalendarView}
                                        className={classes.selectTipoAgenda}
                                    />
                                    :
                                    <>
                                        {typesCalendarView.map(item =>
                                            <Radio
                                                key={item.value}
                                                value={item.value}
                                                className={classes.radioButton}
                                                checked={atendimentoStore.typeCalendarViewSelected === item.value}
                                                icon={item.iconRadio}
                                                onChange={() => this.onChangeTypeCalendarView(item.value)}
                                                tooltipTitle={item.label}
                                                openDrawer={openDrawer}
                                                isIconImg={item.value === 'threeDaysView'}
                                            />
                                        )}
                                    </>
                            }

                        </Grid>
                        <Grid className={classes.rootPanelLeft} item container direction={"column"} wrap={'nowrap'}>

                            <Grid item>
                                <Grid container justify={"center"} className={classes.tableVigencias}>
                                    {this.renderPicker(atendimentoStore.typeCalendarViewSelected)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className={classNames(classes.rootPanelLeftFiltro, !openDrawer && classes.centralizaFiltro)} item container direction={"column"} wrap={'nowrap'}>
                            <Grid className={classes.tableBlockRoot} item>
                                {openDrawer ?
                                    <>
                                        <div className={classes.tituloFiltro}>Filtros</div>
                                        <Grid container justify={"center"} className={classes.checkboxOptions}>
                                            {
                                                this.props.atendimentoStore.filtroSituacoes.map((item, index) =>
                                                (
                                                    <CheckboxAtendimento
                                                        checked={atendimentoStore.status.includes(
                                                            item.value
                                                        )}
                                                        color={item.cor}
                                                        value={item.value}
                                                        label={item.name}
                                                        key={index}
                                                        onChange={e =>
                                                            this.changeCheckbox(
                                                                e,
                                                                atendimentoStore.status
                                                            )
                                                        }
                                                    />
                                                ))
                                            }
                                        </Grid>
                                    </>
                                    :
                                    <FiltroSituacao changeDate={atendimentoStore.selectedDate} />
                                }
                            </Grid>
                        </Grid>
                    </PanelLeft>
                    {openDrawer ?
                        (<IconButton onClick={this.handleDrawerClose} className={classes.menuButton} >
                            <ChevronLeftIcon />
                            <span className={classes.labelMinimizar}> Minimizar barra lateral </span>
                        </IconButton>)
                        :
                        (<IconButton
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButtonMinimizado, {
                                [classes.hide]: this.state.open,
                            })}
                        >
                            <ChevronRightIcon />
                        </IconButton>)
                    }

                </Drawer>
                <Grid container className={classes.tela}>
                    <Grid container direction='column' className={classes.root} wrap={"nowrap"}>
                        <Grid item xs={12} container direction='row' className={classes.root} wrap={"nowrap"}>

                            <Grid item container direction={"column"} wrap={"nowrap"}>
                                <Grid item className={classes.gridHeader}>
                                    <Header disableShadow padding={true} className={classes.header}>
                                        <Grid item xs={11}>
                                            <Grid
                                                container
                                                justify={"space-between"}
                                                alignItems={"center"}
                                                className={classes.headerContent}
                                            >
                                                {atendimentoStore.tipoProfissionalLogado !== 'tecnico' && (
                                                    <>
                                                        <Grid item>
                                                            <InputSearchFilter
                                                                onChange={this.onChangeSearch}
                                                                value={this.state.searchPacient}
                                                                placeholder="Pesquisa por nome, CPF ou telefone"
                                                                handleChangeProfissionalInput={(event) => this.handleChangeSearchFilter(event, 'profissionalSaudeId')}
                                                                profissionalSaude={this.state.listaProfissinalSaude || []}
                                                                search={this.handleClickSearch}
                                                                searchHorariosDisponiveis={this.handleClickSearchHorariosDisponiveis}
                                                                filterPacient={this.state.filterPacient}
                                                                filterHorarioDisponivel={this.state.filterHorarioDisponivel}
                                                                redefinirFiltros={this.redefinirFiltros}
                                                                clearSearch={this.handleClickClear}
                                                                onKeyPress={this.onKeypressSearchPacient}
                                                                handleChangeInput={this.handleChangeSearchFilter}
                                                                screen={'Atendimento'}
                                                                disabledButton={atendimentoStore.loadingHorariosDisponiveis}
                                                            />
                                                        </Grid>
                                                        <AtendimentoButtonHeader
                                                            handleClickEncaixes={this.handleClickEncaixes}
                                                            handleClickSalas={this.handleClickSalas}
                                                        />
                                                    </>
                                                )}
                                                {atendimentoStore.tipoProfissionalLogado === 'tecnico' && (
                                                    <Grid item>
                                                        <div className={classes.filtroHeader}>
                                                            <FiltroHeader screen="AGENDAMENTO_SALA" />
                                                        </div>
                                                    </Grid>)}

                                                <div className={classes.profile}>
                                                    <Profile />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Header>
                                </Grid>

                                {this.props.atendimentoStore.isSalaSelected ?
                                    !atendimentoStore.loadingCalendar &&
                                    <SplitPane
                                        split="vertical"
                                        defaultSize={atendimentoStore.splitPosition || '50%'}
                                        style={{ position: 'static' }}
                                        className={classes.splitPane}
                                        onChange={(size) => atendimentoStore.splitPosition = size}
                                    >
                                        <div className={classes.flexColumn} >
                                            {this.renderHeaderCalendarioProfissional()}
                                            {this.renderCalendar(atendimentoStore.typeCalendarViewSelected)}
                                        </div>
                                        <div className={classes.flexColumn} >
                                            <div className={classes.headerSelecaoAgendas}>
                                                <CancelIcon onClick={() => this.handleClickSalas()} />
                                                <FiltroHeader screen="AGENDAMENTO_SALA" />
                                            </div>
                                            {!atendimentoStore.loadingCalendarSala && this.renderCalendar(atendimentoStore.typeCalendarViewSelected, 'salaCalendar')}
                                        </div>
                                    </SplitPane>
                                    :
                                    (this.props.atendimentoStore.tipoProfissionalLogado === 'tecnico' ?
                                        (!atendimentoStore.loadingCalendarSala && this.renderCalendar(atendimentoStore.typeCalendarViewSelected, 'salaCalendar'))
                                        :
                                        (
                                            <div className={classNames(
                                                this.state.isTelaCheia ? classes.telaCheia : classes.semTelaCheia,
                                                atendimentoStore.typeCalendarViewSelected === "threeDaysView" && classes.telaCheiaThreeDaysView,
                                                atendimentoStore.typeCalendarViewSelected === "multiAgendaView" && classes.contentMultipleDays,
                                            )}>
                                                {this.renderHeaderCalendarioProfissional()}
                                                {atendimentoStore.loadingHorariosDisponiveis ?
                                                    <div className={classes.notFoundContainer}>
                                                        <CircularProgress />
                                                    </div>
                                                    :
                                                    this.renderCalendar(atendimentoStore.typeCalendarViewSelected)}
                                            </div>
                                        )
                                    )
                                }

                            </Grid>
                        </Grid>

                        {this.props.atendimentoStore.openModalRealizarProcedimentos &&
                            <ModalProcedimentosRealizados
                                handleClose={() => { this.props.atendimentoStore.openModalRealizarProcedimentos = false }}
                                idSujeitoAtencao={this.props.atendimentoStore.objView?.sujeitoAtencao?.id}
                                showModal={this.props.atendimentoStore.openModalRealizarProcedimentos}
                                idAgendamento={atendimentoStore.idOpened}
                                isModalSala
                                pageTitle="Agenda - Realizar procedimentos"
                            />}
                        {this.props.atendimentoStore.open && <AtendimentoModal />}

                        {atendimentoStore.openModalListaEspera && (
                            <ModalListaEspera
                                open={atendimentoStore.openModalListaEspera}
                                onClose={() => atendimentoStore.closeModalListaEspera()}
                                profissionalSaudeSelected={filtroHeaderStore.profissionalSaude}
                                loadListaEspera={this.loadListaEspera}
                            />
                        )}
                        {atendimentoStore.openModalListaEsperaItem && (
                            <ModalListaEsperaItem
                                open={atendimentoStore.openModalListaEsperaItem}
                                onClose={() => atendimentoStore.closeModalListaEsperaItem()}
                                loadListaEspera={this.state.loadListaEspera}
                            />
                        )}
                        <AlertDialog
                            open={atendimentoStore.excluirAgendamentoAlertData.openAlert}
                            alertTitle={atendimentoStore.excluirAgendamentoAlertData.title}
                            alertDescription={atendimentoStore.excluirAgendamentoAlertData.description}
                            onCancel={this.handleExcluirAgendamentoCancel}
                            onOk={this.handleExcluirAgendamentoOk}
                            loadingOk={atendimentoStore.cancelandoAgendamento}
                        />
                        {openModalBloquear && (
                            <ModalBloquearAgenda
                                open={openModalBloquear}
                                close={() => this.setState({ openModalBloquear: false })}
                                operation={operation}
                                handleOpenModalConfirmacao={this.handleConfirmModificarAgenda}
                                isLoading={isLoadingModificarHorario}
                                profissionalSaudeId={filtroHeaderStore.profissionalSaude.id}
                                
                            />
                        )}
                          {/* {openModalCancelamento && (
                            <ModalCancelamento
                                open={openModalCancelamento}
                                close={() => this.setState({ openModalCancelamento: false })}
                                handleOpenModalConfirmacao={this.handleCancelarAgendamentos}
                                isLoading={isLoadingModificarHorario}
                                horariosOptions={atendimentoStore.horariosOptions}
                                openModalConfirmarCancelamento={this.handleModalConfirmarCancelamento}

                            />
                        )}
                        {openModalConfirmarCancelamento && horariosPendentes?.length > 0 &&(
                            <ModalConfirmarCancelamento 
                            close={()=>this.setState({openModalConfirmarCancelamento:false,})}
                            horariosSelecionados={horariosPendentes}
                            />
)
                        } */}
                        {openModalConfirmacao && horariosPendentes?.length > 0 && (
                            <ModalConfirmarBloqueio
                                open={this.state.openModalConfirmacao}
                                close={() => this.handleCloseModalConfirmacao()}
                                closeModalBloquearAgenda={() => this.setState({ openModalBloquear: false, horariosPendentes: [] })}
                                operation={operation}
                                horariosPendentes={horariosPendentes}
                                handleConfirm={() => this.onFinishModificarAgenda()}
                                isLoading={isLoadingModificarHorario}
                            />
                        )}
                        <Notification
                            close={this.closeAlertMessage}
                            reset={this.closeAlertMessage}
                            isOpen={this.state.notification.open}
                            variant={this.state.notification.type}
                            message={this.state.notification.message}
                        />
                    </Grid>
                </Grid>
                <Footer />
            </div>
        )
    }

}

export default withStyles(styles)(Atendimento)
